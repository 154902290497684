import { BASE_BLOCK_MODEL } from '../components/creative-editor/helpers/creative-editor-edit.helpers';

class CreativeModelsHelpers {
    /**
     * Get the model path. This helpers prevents a lot of code like `${BASE_BLOCK_MODEL}.overwrites.${selectedFormats[i]}${creativeModel ? `.${creativeModel}` : ''}`
     * @param basePath if the model path should start with the base block model
     * @param isOverwrite if the model path is for an overwrite
     * @param creativeModel the model of the creative
     * @param formatKey the key of the format
     * @param itemModel the model of the item
     * @example If you need something like linkedBlockModels.push(`${BASE_BLOCK_MODEL}.overwrites.${selectedFormats[i]}${creativeModel ? `.${creativeModel}` : ''}`)
     * you can use CreativeModelsHelpers.getModelPath(true, true, creativeModel, selectedFormats[i])
     */
    static getModelPath(basePath = false, isOverwrite = false, creativeModel = '', formatKey?: string, itemModel?: string): string {
        let model = '';

        if (basePath) {
            model = `${BASE_BLOCK_MODEL}.`;
        }

        if (isOverwrite) {
            model += `overwrites.`;
        }

        if (formatKey) {
            model += `${formatKey}.`;
        }

        if (creativeModel) {
            model += `${creativeModel}.`;
        }

        if (itemModel) {
            model += `${itemModel}`;
        }

        // if the model ends with an extra dot, remove it
        if (model.endsWith('.')) {
            model = model.slice(0, -1);
        }

        return model;
    }
}

export default CreativeModelsHelpers;
