import set from 'lodash/set';
import cloneDeep from 'helpers/cloneDeep';
import Templates from 'components/data/Templates';
import { TemplateAsset } from 'components/template-management/types/template-management.type';
import { convertTemplateToAssetV2 } from 'components/data/Templates/helpers/template-converter';
import AssetManagementService from 'components/asset-management/services/asset-management.service';
import { Brick } from 'components/bricks/types/brick.type';
import { CreativeV2, CreativeV2Enriched, CreativeV2TemplateEnriched } from '../components/creative-editor/types/creativeV2.type';
import { isCreativeEnriched, isTemplateCreative } from '../guards/creative-type-guards';
import { getCreativeInstance } from './creatives-factory';
import { TemplateManager } from '../data/template-manager';

class CreativeV2Helpers {
    static enrichCreative = async (creative: CreativeV2 | CreativeV2Enriched): Promise<CreativeV2Enriched> => {
        const enrichedCreative = cloneDeep(creative);

        if (isCreativeEnriched(enrichedCreative)) return enrichedCreative as CreativeV2Enriched;

        // if assetManagerId is not set we cannot enrich the creative
        if (!enrichedCreative.assetManagerId) return enrichedCreative;

        const creativeData: any = await AssetManagementService.getAsset(enrichedCreative.assetManagerId);

        // Sometimes custom display ad templates set the templateInput directly, instead of using the frames object. This fixes that.
        if (creativeData?.data?.templateType === 'displayAd' && !creativeData.data.templateInput?.frames) {
            creativeData.data.templateInput = {
                frames: {
                    frame1: creativeData.data.templateInput || {}
                }
            };
        }

        // if the creative does not exist we are not setting the data variable
        // the creative editor will throw a warning to the user that something went wrong
        if (!creativeData) throw new Error('Creative data does not exist');

        // we only save the data of the creative coming from AMV2 because we only need the data part and not the status, last edited, etc.
        enrichedCreative.data = creativeData.data;

        if (isTemplateCreative(enrichedCreative)) {
            return await CreativeV2Helpers.enrichCreativeV2Template(enrichedCreative as CreativeV2TemplateEnriched);
        }

        return enrichedCreative;
    };

    static enrichCreativeV2Template = async (creative: CreativeV2TemplateEnriched): Promise<CreativeV2TemplateEnriched> => {
        const creativeEnrichedTemplate: CreativeV2TemplateEnriched = cloneDeep(creative);

        if (!creativeEnrichedTemplate?.data) return creative;

        let template = await Templates.asyncGetTemplatesItem(creativeEnrichedTemplate.data.templateType, creativeEnrichedTemplate.data.templateId);

        // We have to convert the old template structure to the new one as creative editor uses the new template structure
        if (template) {
            template = convertTemplateToAssetV2(template) as TemplateAsset;
        }

        // Add the template to the static data manager, so we only have to set it once
        if (template && template.data.identifier) TemplateManager.addTemplate(template);

        // if activeFormats in a creativeV2Template is undefined we are setting all the existing formats in the template as active
        if (!creativeEnrichedTemplate.data.settings.activeFormats) {
            if (template.data.settings && template.data.settings.formats) {
                creativeEnrichedTemplate.data.settings.activeFormats = cloneDeep(template.data.settings.formats);
            }

            if (template.data.templateSetup && template.data.templateSetup.formats) {
                creativeEnrichedTemplate.data.settings.activeFormats = cloneDeep(template.data.templateSetup.formats.map((format) => format.key));
            }
        }

        // we are setting the default template input values of the template otherwise the creative editor will throw an error that templateInput doesnt exists
        if (!creativeEnrichedTemplate.data.templateInput) {
            const baseModel = getCreativeInstance(creativeEnrichedTemplate).getCreativeModel('frame1');

            set(creativeEnrichedTemplate, `data.templateInput${baseModel ? `.${baseModel}` : ''}`, {});
        }

        return creativeEnrichedTemplate;
    };

    /**
     * Fetches and enriches creatives for a given brick.
     * @param {Brick} brick - The brick object containing the creatives.
     * @returns {Promise<CreativeV2Enriched[] | undefined>} - The enriched creatives.
     * @throws {Error} If no creatives are found or an error occurs during enrichment.
     */
    static getCreativesForBrick = async (brick: Brick): Promise<CreativeV2Enriched[]> => {
        const creatives = brick?.data?.creatives;

        if (!creatives) return [];

        try {
            const enrichedCreatives: CreativeV2Enriched[] = await Promise.all(creatives.map((creative) => CreativeV2Helpers.enrichCreative(creative)));
            return enrichedCreatives;
        } catch (error) {
            throw new Error('Error fetching the creatives.');
        }
    };
}

export { CreativeV2Helpers };
