import { TemplateAsset } from 'components/template-management/types/template-management.type';

/**
 * TemplateManager is a class for managing templates in a centralized manner.
 * It provides methods for adding, retrieving, and accessing templates by their identifier.
 * Storing templates in a separate class allows for efficient management and prevents duplicate entries. We also prevent
 * adding the templates in the setState or ComponentStore.
 */
class TemplateManager {
    private static templates: Record<string, TemplateAsset> = {};

    /**
     * Retrieve a template by its identifier.
     * @param identifier The unique identifier of the template.
     * @returns The template corresponding to the given identifier, or undefined if not found.
     */
    public static getTemplateByIdentifier(identifier: string): TemplateAsset | undefined {
        return TemplateManager.templates[identifier];
    }

    /**
     * Add a new template to the TemplateManager.
     * @param template The template to be added.
     * If a template with the same identifier already exists, it will not be added.
     */
    public static addTemplate(template: TemplateAsset): void {
        if (!template.data.identifier) return;
        // Check if the template already exists
        if (!TemplateManager.templates[template.data.identifier]) {
            // If it's not a duplicate, add the template
            TemplateManager.templates[template.data.identifier] = template;
        }
    }

    /**
     * Check if a template with the given identifier already exists in the TemplateManager.
     * @param identifier The identifier of the template to check.
     * @returns True if a template with the given identifier exists, otherwise false.
     */
    public static templateExists(identifier: string): boolean {
        return TemplateManager.templates[identifier] !== undefined;
    }
}

export { TemplateManager };
