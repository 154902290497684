import PropTypes from 'prop-types';
import React, { Component } from 'react';
import NativeSelect from '@mui/material/NativeSelect';
import EditorData from 'components/editor-data/EditorData';
import '../styles/main.scss';
/**
 * KLM Origin Switch
 * This component allows the user to set the active origin from the current campaign origins.
 */
class KLMOriginSwitch extends Component {
    static propTypes = {
        onMutation: PropTypes.func
    };

    static defaultProps = {
        onMutation: () => {}
    };

    constructor(props) {
        super(props);

        // Get origins
        const origins = EditorData.getValueFromModel('settings.origins');

        // Setup state
        this.state = {
            origins: origins
        };
    }

    onChangeOrigin = (event) => {
        const origin = event.target.value;
        EditorData.setEditorState('origin', origin);
    };

    render() {
        const { origins } = this.state;
        const { origin } = this.props;

        return (
            <NativeSelect
                value={origin}
                onChange={this.onChangeOrigin}
                inputProps={{ id: 'origin-selector' }}
                variant="outlined"
                fullWidth
                margin="dense"
                classes={{
                    root: 'klm-origin-switch'
                }}>
                <option value="GEN">General</option>
                {origins &&
                    origins.map((item) => (
                        <option key={item.id} value={item.id}>
                            {item.name}
                        </option>
                    ))}
            </NativeSelect>
        );
    }
}

export default KLMOriginSwitch;
