import { FieldSetWrapper } from 'components/input/FieldSet';
import EditorDynamicDataWrapper from 'components/editor-data/EditorDynamicDataWrapper';
import KLMOriginSwitch from './components/index';

const FieldSetKLMOriginSwitch = FieldSetWrapper(KLMOriginSwitch);
const KLMOriginSwitchDynamicData = EditorDynamicDataWrapper(KLMOriginSwitch);
const FieldSetKLMOriginSwitchDynamicData = EditorDynamicDataWrapper(FieldSetKLMOriginSwitch);

export default FieldSetKLMOriginSwitchDynamicData;
export { KLMOriginSwitch, KLMOriginSwitchDynamicData, FieldSetKLMOriginSwitch, FieldSetKLMOriginSwitchDynamicData };
