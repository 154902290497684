import {
    CreativeV2,
    CreativeV2CustomUpload,
    CreativeV2Enriched,
    CreativeV2Media,
    CreativeV2Template
} from '../components/creative-editor/types/creativeV2.type';

// Type guard functions
export function isTemplateCreative(creative: CreativeV2): creative is CreativeV2Template {
    return creative?.type === 'template';
}

export function isMediaCreative(creative: CreativeV2): creative is CreativeV2Media {
    return creative?.type === 'media';
}

export function isCustomUploadCreative(creative: CreativeV2): creative is CreativeV2CustomUpload {
    return creative?.type === 'customUpload';
}

export function isCreativeEnriched(creative: CreativeV2 | CreativeV2Enriched): creative is CreativeV2Enriched {
    return creative['data'] && Object.keys(creative['data']).length > 0;
}
